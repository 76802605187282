<template>
    <div class="index">
        <div class="index_header">
            <van-nav-bar :title="title" @click-left="onBack" left-arrow/>
        </div>
        <div class="index-step" v-show="steps > 1">
            <van-steps :active="step">
                <van-step v-for="(item, index) in showStepArr" :key="index">
                    <i slot="active-icon" class="iconfont iconxuanzeanniu1"></i>
                    <i slot="inactive-icon" class="iconfont"
                       :class="(step == 2 ||  index == 0)? 'iconxuanzeanniu1' : 'iconxuanze2'"></i>
                </van-step>
            </van-steps>
        </div>
        <div class="index-box">
            <mu-expand-transition>
                <div v-show="showBox(1)">
                    <div class="tip-title">上传手持身份证正面（人像面）照片</div>
                    <div class="sfz_box">
                        <van-uploader v-model="sfzList"
                                      id="sfz_upload"
                                      :after-read="afterSfzRead"
                                      multiple :max-count="1"/>
                        <div v-if="sfzList.length === 0"
                             class="sfz_tip"
                             @click.prevent="(()=>{tipSfzShow = true;})"></div>
                    </div>
                    <van-button
                            @click="saveImage(0)"
                            size="large"
                            :disabled="sfzList.length === 0 || !sfzNext"
                            block color="linear-gradient(to right, #563279, #8b2d87)">
                        下一步
                    </van-button>
                    <van-dialog v-model="tipSfzShow"
                                class="tip-sfz-box"
                                confirm-button-text="知道了"
                                title="证件上传示例" @confirm="tipSfzUpload">
                        <img class="tip-sfz-img" :src="img.sfz"/>
                    </van-dialog>
                </div>
            </mu-expand-transition>

            <mu-expand-transition>
                <div v-show="showBox(2)">
                    <div class="tip-title">上传考试机接口照片</div>
                    <div class="jk_box">
                        <van-uploader v-model="jkList"
                                      id="jk_upload"
                                      :after-read="afterJkRead"
                                      multiple :max-count="3"/>
                        <div v-if="jkList.length === 0"
                             class="jk_tip"
                             @click.prevent="tipJkShowAc"></div>
                    </div>
                    <van-button
                            @click="saveImage(1)"
                            size="large"
                            :disabled="jkList.length === 0"
                            block color="linear-gradient(to right, #563279, #8b2d87)">下一步
                    </van-button>
                    <van-dialog v-model="tipJkShow"
                                class="tip-jk-box"
                                confirm-button-text="知道了"
                                title="接口照片示例" @confirm="tipJkUpload">
                        <div class="tip-jk-box">
                            <p>笔记本电脑</p>
                            <img class="tip-sfz-img" :src="img.sb1"/>
                            <p>台式机</p>
                            <img class="tip-sfz-img" :src="img.sb2"/>
                            <img class="tip-sfz-img" :src="img.sb3"/>
                            <div class="tip-jk-box-bottom"></div>
                        </div>
                    </van-dialog>
                </div>
            </mu-expand-transition>

            <mu-expand-transition>
                <div v-show="showBox(3)">
                    <div class="tip-title">上传考试机环境照片</div>
                    <div class="hj_box">
                        <van-uploader v-model="hjList"
                                      id="hj_upload"
                                      :after-read="afterHjRead"
                                      multiple :max-count="3"/>
                        <div v-if="hjList.length === 0"
                             class="hj_tip"
                             @click.prevent="tipHjShowAc()"></div>
                    </div>
                    <van-button
                            @click="saveImage(2)"
                            :disabled="hjList.length === 0"
                            block color="linear-gradient(to right, #563279, #8b2d87)">下一步
                    </van-button>
                    <van-dialog v-model="tipHjShow"
                                class="tip-jk-box"
                                confirm-button-text="知道了"
                                title="环境照片示例" @confirm="tipHjUpload">
                        <div class="tip-jk-box">
                            <p>考试所处环境中所有视角的照片</p>
                            <img class="tip-sfz-img" :src="img.hj1"/>
                            <img class="tip-sfz-img" :src="img.hj2"/>
                            <img class="tip-sfz-img" :src="img.hj3"/>
                            <div class="tip-jk-box-bottom"></div>
                        </div>
                    </van-dialog>
                </div>
            </mu-expand-transition>
        </div>
    </div>
</template>


<script>
    import {indexMixin} from "mixins/index/index";
    import {Step, Steps, NavBar, Uploader} from 'vant';

    export default {
        name: "index",
        mixins: [indexMixin],
        data() {
            return {};
        },
        methods: {},
    }
</script>

<style scoped lang="scss">
    .index {
        background: #fff;

        .index-step {
            width: 80%;
            margin: 0 auto;
        }

        .van-steps__items {
            .iconxuanzeanniu1 {
                color: #563279;
            }

            .iconxuanze2 {
                color: #E6E6E6;
            }
        }

        .index-box {
            width: 94%;
            margin: 0 auto;

            .sfz_box {
                position: relative;
                margin-bottom: 60%;
                display: inline-block;

                .sfz_tip {
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    z-index: 1;
                    display: inline-block;
                    left: 0;
                }
            }

            .tip-sfz-img {
                height: 200px;
                margin: 30px auto;
                text-align: center;
            }

            .jk_box {
                position: relative;
                margin-bottom: 60%;
                display: inline-block;

                .jk_tip {
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    z-index: 1;
                    display: inline-block;
                    left: 0;
                }
            }

            .tip-jk-box {
                height: 400px;
            }

            .hj_box {
                position: relative;
                margin-bottom: 60%;
                display: inline-block;

                .hj_tip {
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    z-index: 1;
                    display: inline-block;
                    left: 0;
                }
            }

            .tip-title {
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #333;
                margin: 10px 0 15px;
            }
        }

    }
</style>
<style lang="scss">
    .van-dialog__confirm, .van-dialog__confirm:active {
        color: #8b2d87;
    }

    .index {
        .tip-sfz-box {
            .van-dialog__content {
                text-align: center;
            }
        }

        .tip-jk-box {
            margin-top: 20px;

            .van-dialog__content {
                text-align: center;
                overflow-y: scroll;

                > img:last-child {
                    padding-bottom: 300px;
                }
            }

            .tip-jk-box-bottom {
                height: 130px;
            }

            .van-dialog__footer {
                z-index: 999;
                position: absolute;
                bottom: 0;
                width: 100%;
                background: #fff;
            }
        }

        .van-steps__items {
            .van-step__circle-container {
                padding: 0;
            }

            .van-step__line {
                height: 2px;
            }
        }

        .van-step--finish .van-step__circle, .van-step--finish .van-step__line {
            background-color: #563279;
        }

        .van-uploader__upload, .van-uploader__preview {
            width: 160px;
            height: 160px;
            border-radius: 12px;
            margin-right: 15px;
        }

        .van-uploader__preview-image {
            width: inherit;
            height: inherit;
            border-radius: 12px;
        }

        .van-uploader__mask {
            border-radius: 12px;
        }

        .van-uploader__preview-delete {
            width: 25px;
            height: 25px;
            z-index: 9;
            border-radius: 0 12px 0 12px;

            i {
                font-size: 25px;
            }
        }

        .van-uploader__upload i {
            font-size: 50px;
            color: #E5E5E5;
        }
    }
</style>
