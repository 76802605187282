//首页相关
import {saveStudentImage,getSignature} from "r/index/index";
import {fileUpload} from "r/index/upload";
import { Dialog,Toast } from 'vant';
import sfz from "a/images/index/sfz01.png"
import hj1 from "a/images/index/hjimg-1.png"
import hj2 from "a/images/index/hjimg-2.png"
import hj3 from "a/images/index/hjimg-3.png"
import sb1 from "a/images/index/sbimg-4.png"
import sb2 from "a/images/index/sbimg-5.png"
import sb3 from "a/images/index/sbimg-6.png"



export const indexMixin = {
  data() {
    return {
      title:'上传照片',
      step:0,
      steps:0,
      stepArr:0,
      showStepArr:0,
      toast: {},
      img:{
        sfz:sfz,
        hj1:hj1,
        hj2:hj2,
        hj3:hj3,
        sb1:sb1,
        sb2:sb2,
        sb3:sb3,
      },
      sfzNext:false,
      tipSfzShow:false,
      tipJkShow:false,
      tipHjShow:false,
      sfzList:[],
      jkList:[],
      hjList:[],
    };
  },
  created() {
    let camera = localStorage.getItem("monitor-camera");
    let steps = localStorage.getItem("monitor-steps");
    steps = steps.split(',');
    this.stepArr = steps;
    let str = 0;
    this.showStepArr = steps.filter((val)=>{
      if(val == 1){
        ++str;
        return 1;
      }
    });
    this.steps = str;
    if(camera){
      let has_step = localStorage.getItem("monitor-has_step");
      if(has_step != 0 || this.steps > 0){
        let step = localStorage.getItem("monitor-step");
        if(step == 3){
          this.$router.replace('/monitor');
          return;
        }
        if(this.steps <= step){
          this.$router.replace( '/monitor');
          return;
        }
        this.step = step ? parseInt(step) : 0;
        this.setTitle();
      }else {
        this.$router.replace('/monitor');
      }
    }else {
      this.$router.replace('/position');
    }

  },
  methods: {
    onBack(){
      if(this.step === 0){
        this.$router.replace('/position');
      }else {
        this.step = --this.step;
        this.setTitle();
      }
    },
    async saveImage(type){
      let fileName = '';
      if(type === 0){
        fileName = this.sfzList[0].fileName;
      }
      if(type === 1){
        if(this.sfzList.length < 1){
          return false;
        }
        for (let i = 0;i<this.jkList.length;i++){
          fileName += (fileName ? ',' : '') +this.jkList[i].fileName;
        }
      }
      if(type === 2){
        if(this.sfzList.length < 1){
          return false;
        }
        if(this.jkList.length < 1){
          return false;
        }
        for (let i = 0;i<this.hjList.length;i++){
          fileName += (fileName ? ',' : '') +this.hjList[i].fileName;
        }
      }
      if(!fileName){
        return false;
      }
      this.toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      let {data} = await saveStudentImage(fileName,type);
      if(data.code == 200){
        this.step = ++this.step;
        this.setTitle();
        localStorage.setItem("monitor-step",""+this.step);
      }
      if(this.steps == this.step){
        await this.$router.replace('/monitor');
      }
      this.toast.close();
    },
    setTitle(){
      this.title = '上传照片';

      window.document.title = this.title;
    },
    getFileType(filePath){
      let startIndex = filePath.lastIndexOf(".");
      if(startIndex !== -1)
        return filePath.substring(startIndex+1, filePath.length).toLowerCase();
      else return "";
    },
    async afterSfzRead(file) {
      file.status = 'uploading';
      file.message = '上传中...';
      let zkz = localStorage.getItem('monitor-zkz');
      let fileName = 'sfz_'+zkz+'.'+this.getFileType(file.file.name);
      let {data} = await getSignature();
      let uploadData = await fileUpload(data,file.file,fileName);
      if(uploadData.status == 200 && uploadData.data && uploadData.data.Status === 'OK'){
        file.status = 'success';
        file.message = '上传成功';
        file.fileName = fileName;
        Toast('上传成功');
        this.sfzNext = true;
      }else {
        file.status = 'failed';
        file.message = '上传失败';
        this.sfzNext = false;
      }
    },
    async afterJkRead(file){
      let jkList = this.jkList;
      file.status = 'uploading';
      file.message = '上传中...';
      let zkz = localStorage.getItem('monitor-zkz');
      let arrIndex = '1,2,3';
      for (let i = 0;i<jkList.length;i++){
        if(arrIndex.indexOf(jkList[i].index) > -1){
          arrIndex = arrIndex.replace(jkList[i].index,'')
        }
      }
      let arr = arrIndex.split(',').filter(Boolean);
      let index = arr[0];
      let fileName = 'jk_'+zkz+'_'+index+'.'+this.getFileType(file.file.name);
      let {data} = await getSignature();
      let uploadData = await fileUpload(data,file.file,fileName);
      if(uploadData.status == 200 && uploadData.data && uploadData.data.Status === 'OK'){
        file.status = 'success';
        file.message = '上传成功';
        file.index = index;
        file.fileName = fileName;
        Toast('上传成功');
      }else {
        file.status = 'failed';
        file.message = '上传失败';
      }
    },
    tipJkShowAc(){
      this.tipJkShow = true;
    },
    tipHjShowAc(){
      this.tipHjShow = true;
    },
    async afterHjRead(file){
      let hjList = this.hjList;
      file.status = 'uploading';
      file.message = '上传中...';
      let zkz = localStorage.getItem('monitor-zkz');
      let arrIndex = '1,2,3';
      for (let i = 0;i<hjList.length;i++){
        if(arrIndex.indexOf(hjList[i].index) > -1){
          arrIndex = arrIndex.replace(hjList[i].index,'')
        }
      }
      let arr = arrIndex.split(',').filter(Boolean);
      let index = arr[0];
      let fileName = 'hj_'+zkz+'_'+index+'.'+this.getFileType(file.file.name);
      let {data} = await getSignature();
      let uploadData = await fileUpload(data,file.file,fileName);
      if(uploadData.status == 200 && uploadData.data && uploadData.data.Status === 'OK'){
        file.status = 'success';
        file.message = '上传成功';
        file.index = index;
        file.fileName = fileName;
        Toast('上传成功');
      }else {
        file.status = 'failed';
        file.message = '上传失败';
      }
    },
    tipJkUpload(){
      document.getElementById('jk_upload').click();
    },
    tipHjUpload(){
      document.getElementById('hj_upload').click();
    },
    tipSfzUpload(){
      document.getElementById('sfz_upload').click();
    },
    showBox(type){
      switch (type) {
        //身份证
        case 1:
            if(this.steps > 0){
              if(this.stepArr[0] == 1 && this.step == 0){
                return true;
              }
            }
          break;
        case 2:
          if(this.steps > 0){
            if(this.stepArr[1] == 1){
              if(this.stepArr[0] == 1 && this.step == 1){
                return true;
              }
              if(this.stepArr[0] == 0 && this.step == 0){
                return true;
              }
            }
          }
          break;
        case 3:
          if(this.steps > 0){
            if(this.stepArr[2] == 1){
              if(this.stepArr[0] == 0 && this.stepArr[1] == 0 && this.step == 0){
                return true;
              }
              if(this.stepArr[0] == 1 && this.stepArr[1] == 0 && this.step == 1){
                return true;
              }
              if(this.stepArr[0] == 0 && this.stepArr[1] == 1 && this.step == 1){
                return true;
              }
              if(this.stepArr[0] == 1 && this.stepArr[1] == 1 && this.step == 2){
                return true;
              }
            }
          }
          break;
        default:
          return false;
      }
      return false;
    }
  },
  destroyed() {
    //window.removeEventListener('popstate', this.onBack, false);
  },
};
